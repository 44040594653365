/*-----------------------
Fieldset
-----------------------*/
fieldset {
  display: inline-block;
  @include rem(margin-bottom, 18px);
  position: relative;
  width: 100%;
}

fieldset .MuiIconButton-root {
  padding: 0 12px !important;
}

/*-----------------------
Labels
-----------------------*/
label {
  color: $black-base;
  display: inline-block;
  @include font-type($primary-sans-serif, normal, $semibold);
  @include s-base;
  @include rem(margin-bottom, 6px);
  text-align: left;

  i {
    font-style: italic;
    font-weight: $regular;
  }
}

/*-----------------------
Inputs
-----------------------*/
#{$all-text-inputs},
select,
textarea {
  background-color: $lightgrey-lighter;
  border: 1px solid $darkgrey-light;
  border-radius: 0px;
  box-sizing: border-box;
  appearance: none;
  transition: all 0.3s ease-in-out;
  @include font-type($primary-sans-serif, normal, 400);
  @include s-base;
  @include rem(padding, 13px);
  width: 100%;
  -webkit-font-smoothing: antialiased;

  @media only screen and (max-width: 600px) {
    font: inherit;
  }

  &::placeholder {
    color: $darkgrey-light;
    font-style: italic;
  }

  &:focus {
    border-color: $black-base;
    outline: none;
  }
}

/*-----------------------
Hidden Input
-----------------------*/
.hidden-field {
  height: 0px;
  width: 0px;
}

// Cypress does not provide a way (or I couldn't find it)
// to set the value of a hidden input. This is to `hide`
// the input with type="text"
.really.hidden-field {
  input {
    padding: 0px;
    border: none;
  }
}

/*-----------------------
Input Icon
-----------------------*/
.icon-label {
  position: relative;
  input {
    @include rem(padding-left, 30px);
  }

  i {
    @include position(absolute, 12px null null 10px);
    background-repeat: no-repeat;
    background-size: 15px;
    height: 20px;
    width: 20px;
  }

  // .twitter-icon{
  //   background-image: asset-url("twitter.svg");
  // }
  // .linkedin-icon{
  //   background-image: asset-url("linkedin.svg");
  // }
  // .web-icon{
  //   background-image: asset-url("web.svg");
  // }
  // .calendar-icon{
  //   background-image: asset-url("calendar.svg");
  //   @include position(absolute, 14px null null 10px);
  //   z-index: 2;
  // }
}

/*-----------------------
Textarea
-----------------------*/
textarea,
textarea[type='text'] {
  height: 100px;
  resize: vertical;
}

.with-character-count {
  label {
    @include rem(padding-right, 108px);
  }

  .character-count {
    color: $black-base;
    float: right;
    @include position(absolute, 0px 0px null null);
    @include s-small;
  }
}

/*-----------------------
Select
-----------------------*/
select {
  background-image: url('../images/utility-icons/dropdown.svg');
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-size: 15px;
  cursor: pointer;
  @include rem(padding-right, 32px);
  text-overflow: ellipsis;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-bottom: 1px solid $black-base;

  option:disabled {
    color: $grey-light;
    font-style: italic;
  }
}

/*-----------------------
Checkbox
-----------------------*/
.checkbox {
  width: 100%;

  label {
    cursor: pointer;
    float: right;
    @include rem(margin, 2px 0px 0px);
    line-height: normal;
    width: calc(100% - 32px);
  }
}
.ReactModal__Content,
.merchant-portal,
.credit-portal {
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    transition: all 0.3s ease-in-out;
    background-color: $lightgrey-lighter;
    border: 1px solid $grey-light;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    @include rem(height, 20px);
    @include rem(width, 20px);
    outline: none;
    display: inline-block;
    @include rem(margin, 0px 8px 0px 0px);

    &:focus {
      border-color: $black-base;
      outline: none;
    }

    &:checked {
      background-color: $darkgrey-base;
      border-color: $darkgrey-base;

      &:after {
        background-image: url('../images/utility-icons/checkmark-white.svg');
        background-size: 24px;
        background-repeat: no-repeat;
        content: '';
        display: block;
        height: 24px;
        transition: all 0.2s ease-in;
        @include position(absolute, 3px null null 4px);
        text-align: center;
        width: 24px;
        -webkit-position: absolute;
        -webkit-z-index: 3;
      }
    }
  }

  td.actions,
  .input-wrapper,
  fieldset.checkbox,
  fieldset.select-all-permissions-checkbox {
    input[type='checkbox'] {
      &:checked {
        &:after {
          background-size: 16px;
          height: 16px;
          width: 16px;
          @include position(absolute, 2px null null 2px);
        }
      }
    }
  }
}

/*-----------------------
Input Icon
-----------------------*/

.pre-icon-container {
  position: absolute;
  left: 0;
  margin-right: 10px;
  color: $black-base;
}

fieldset.pre-icon {
  input {
    padding-left: 20px !important;
  }
}

.post-icon-container {
  position: absolute;
  right: 0;
  top: 28px;
  color: $black-base;
}

.loading-bank-spinner {
  #spinner:after {
    border: 2px solid #a7a7a7;
    border-right-color: transparent;
    border-top-color: transparent;
    height: 24px;
    width: 24px;
  }
}

/*-----------------------
Input w/ Hidden Eye Icon
-----------------------*/
.input-hidden-icon {
  position: absolute;
  right: 0;
  button {
    width: 44px;
    border: none;
    background: transparent;
    cursor: pointer;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}

/*-----------------------
Radio Buttons
-----------------------*/
.radio-buttons {
  display: block;
  width: 100%;

  .label-input {
    display: block;
    margin-bottom: 10px;
  }

  .input-wrapper .row {
    display: block;
    @include grid-container;
    @include grid-collapse;
    margin-bottom: 0px;

    input[type='radio'] {
      position: absolute;
      z-index: -1;
    }

    input[type='radio']:focus ~ span {
      border-color: $black-base;
    }

    label.radio-button {
      background-color: $lightgrey-lighter;
      border-radius: 3px;
      display: inline-block;
      @include grid-column(6 of 12);
      overflow: hidden;
      position: relative;
      text-align: center;
    }

    span {
      background: $lightgrey-lighter;
      border: 1px solid $darkgrey-light;
      border-radius: 4px;
      color: $black-base;
      cursor: pointer;
      display: block;
      @include s-base;
      font-weight: $bold;
      padding: 15px;
      width: 100%;

      &:hover {
        background-color: lighten($darkgrey-base, 65%);
        transition: all 0.3s ease-in-out;
      }
    }

    input:checked + span {
      background-color: $darkgrey-base;
      border-color: $darkgrey-base;
      color: $lightgrey-lighter;
    }
  }
}

/*-----------------------
Radio Inputs
-----------------------*/
.radio-inputs {
  width: 100%;

  label {
    cursor: pointer;
    line-height: normal;
    display: block;
  }

  span {
    color: $black-base;
    display: inline-block;
    @include rem(margin-top, 3px);
    width: calc(100% - 32px);

    p {
      white-space: normal;
    }
  }

  input[type='radio'] {
    -webkit-appearance: radio;
    appearance: none;
    transition: all 0.3s ease-in-out;
    background-color: $lightgrey-lighter;
    border: 1px solid $darkgrey-light;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    @include rem(height, 20px);
    @include rem(width, 20px);
    outline: none;
    display: inline-block;
    @include rem(margin, 0px 8px 0px 0px);
    border-radius: 20em;
    vertical-align: top;

    &:focus {
      border-color: $black-base;
      outline: none;
    }

    &:checked {
      position: relative;
      &:after {
        background-color: $black-base;
        color: $black-base;
        content: '';
        display: block;
        height: 14.5px;
        transition: all 0.2s ease-in;
        text-align: center;
        width: 14.5px;
        -webkit-position: absolute;
        -webkit-z-index: 3;
        border-radius: 20em;
        @include vertical-horizontal-center;
      }
    }
  }
}

/*-----------------------
Error
-----------------------*/
.error {
  input,
  textarea,
  select {
    border: 1px solid $red-base;

    &:focus {
      border-color: $black-base;
      outline: none;
    }
  }
}

.error-fieldset {
  label {
    color: $red-base !important;
  }
}

.error-message,
span.error-message {
  color: $red-base;
  display: inline-block;
  @include rem(margin-top, 4px);
  @include s-tiny;
  transition: all 0.3s ease-in-out;
  &:first-letter {
    text-transform: capitalize;
  }
}
.error-message,
span.error-message {
  font-size: 14px !important;
  border: unset;
  padding: 2px 0;
  color: $red-base;
}
/*-----------------------
Input Tooltip
-----------------------*/
.tooltip-trigger {
  top: 6px;
  position: relative;
}

.tooltip-trigger:after {
  background-color: $grey-light;
  border: 1px solid $lightgrey-light;
  border-radius: 50%;
  color: $darkgrey-light;
  content: '?';
  cursor: pointer;
  display: inline-block;
  height: 18px;
  font-size: 12px;
  margin-left: 10px;
  text-align: center;
  line-height: 1.4;
  vertical-align: text-top;
  width: 18px;
}
.tooltip-trigger:after:hover {
  background: $grey-base;
}

.tooltip-content {
  display: none;
  @include rem(margin-bottom, 8px);

  p {
    line-height: 1.5;
  }

  &.is-active {
    display: block;
    width: auto;
    max-width: 300px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    font-size: 12px;
    background: $grey-light;
    padding: 10px;
    border-radius: 5px;
    // position: absolute;
    // right: 149px;
    // top: -10px;
    z-index: 100;
    opacity: 0.9;
  }
}

/*-----------------------
Uploader
-----------------------*/
.fileupload {
  .thumbnail {
    display: inline-block;
    @include rem(margin-right, 15px);
    width: 70px;
    vertical-align: middle;

    img {
      border-radius: 50%;
      height: 70px;
    }
  }

  .button-secondary-light {
    @include button;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    background-color: $grey-light;
    box-shadow: none;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: darken($grey-light, 8%);
    }
  }

  .button-secondary-light > input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translate(-300px, 0) scale(4);
    cursor: pointer;
  }
}

.fileupload-exists .fileupload-new,
.fileupload-new .fileupload-exists {
  display: none;
}

.fileupload-preview {
  @include rem(margin, 0px 10px);
  @include s-tiny;
  vertical-align: middle;
}

.fileupload .close {
  text-decoration: none;
  vertical-align: middle;
  @include s-medium;
}

/*-----------------------
Date Picker
-----------------------*/
.react-datepicker__input-container {
  width: 100%;
}

/*-----------------------
Color Picker
-----------------------*/
.color-picker {
  .color {
    border: 1px solid $lightgrey-light;
    height: 20px;
    @include position(absolute, 32px null null 10px);
    width: 20px;
  }

  .hex {
    @include position(absolute, 35px null null 38px);
  }

  .color-input {
    @include rem(padding-left, 50px);
  }
}

/*-----------------------
Button Area
-----------------------*/
.button-area {
  button {
    width: 100%;

    @include grid-media($tablet) {
      width: inherit;
    }
  }

  a {
    display: inline-block;
    @include rem(margin-top, 20px);
    text-align: center;
    width: 100%;

    @include grid-media($tablet) {
      @include rem(margin-top, 10px);
      float: right;
      width: inherit;
    }
  }
}

/*-----------------------
Buttons Centered for Mobile
-----------------------*/
@media (max-width: 440px) {
  .practice-search-form-buttons {
    position: relative;
    text-align: center;
    .button-warn-outline {
      margin-bottom: 10px;
    }
    button {
      margin: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

/*-----------------------
Checkbox
------------------------*/
.label-checkbox {
  display: flex;
  margin-bottom: 30px;
}

/*-----------------------
Forms
-----------------------*/
.ReactModal__Content,
.merchant-portal,
.credit-portal {
  form {
    .row {
      margin-bottom: 0px;
    }
  }
  fieldset {
    position: relative !important;
    margin-bottom: 3rem !important;

    input,
    select {
      border: unset;
      padding: 1em 0;
      border-bottom: 1px solid $black-base;
      &::placeholder {
        font-style: normal !important;
      }
    }
  }
}

.select-all-permissions-checkbox {
  margin-top: 20px;
  margin-bottom: 0 !important;
  input[type='checkbox'] {
    height: 22px !important;
    width: 22px !important;
  }
  label {
    cursor: pointer;
    float: right;
    margin: 2px 0px 0px;
    margin: 0.125rem 0px 0px;
    line-height: normal;
    width: calc(100% - 32px);
  }
}
input[type='checkbox'] {
  border: 1px solid $black-base !important;
  padding: unset !important;
  height: 22px !important;
  width: 22px !important;
  &:checked {
    &:after {
      left: 1px;
      top: 0px;
    }
  }
}

input[type='checkbox'].select-for-print {
  height: 34px !important;
  width: 34px !important;
  vertical-align: middle;

  &:after {
    background-image: url('../images/utility-icons/checkmark-white.svg');
    background-size: 28px;
    content: '';
    display: block;
    height: 28px;
    transition: all 0.2s ease-in;
    @include position(absolute, 1px null null 2px);
    text-align: center;
    width: 28px;
    -webkit-position: absolute;
    -webkit-z-index: 3;
  }
}

/** INPUT DATE MASK **/
input:not([type]).input-date-mask {
  font-size: 18px;
  padding: 10px 0 10px;
}

.input-text-hint {
  font-weight: 400;
  font-family: 'LatoRegular';
  font-size: 10px;
  color: $darkgrey-base;
  line-height: 12px;
}

.input-text-hint a {
  padding-bottom: 1px;
  display: inline-block;
  font-size: 10px;
  color: $blue-base;
  text-decoration: underline !important;
}

.label-note {
  font-family: 'LatoItalic';
  font-style: italic;
  font-weight: 400;
  margin-left: 4px;
}

/** SUCCESS MESSAGE **/
.form-succes-msg {
  margin: 0 0 20px;

  h3 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    font-size: 0.8125rem;
    margin: 0 0 0.375rem;
  }
  p {
    font-size: 14px;
  }
}

/** READ ONLY FIELDS **/
.fieldset-readonly {
  margin-bottom: 30px;
}
.label-readonly {
  color: #000;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  font-size: 0.8125rem;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
  text-align: left;
}
.field-readonly {
  background-color: #ffffff;
  border: none;
  border-radius: 0px;
  box-sizing: border-box;
  appearance: none;
  transition: all 0.3s ease-in-out;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  font-size: 0.8125rem;
  padding: 0.8125rem 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
}

.field-note {
  margin: -30px 0 30px 0;
  li {
    list-style-type: disc;
    margin-bottom: 20px;
  }
}

.ssn-tip {
  display: flex;
  margin-top: 10px;
  svg {
    margin-top: 6px;
    margin-right: 6px;
    color: $darkgrey-base;
  }
  p {
    font-family: 'LatoRegular';
    color: $darkgrey-base;
  }
}

.section-title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $lightgrey-light;
  margin-bottom: 20px;
  border-radius: 5px;
  h2 {
    font-family: "'LatoBold'";
    margin: 0 0 0 20px;
    padding: 20px;
  }
}

.credit-portal {
  input[type='checkbox'] {
    &:checked {
      &:after {
        background-size: 16px !important;
        height: 16px !important;
        width: 16px !important;
        top: 2px !important;
        left: 2px !important;
      }
    }
  }
}
