.practice-search-input {
  width: 70%;
  min-width: 270px;
  input {
    border: unset;
    border-bottom: 1px solid $black-base;
    &::placeholder {
      padding-left: 2px;
      font-size: 12px;
      font-style: normal;
      letter-spacing: 0px;
      color: $darkgrey-base;
    }
  }
  .spinner {
    height: 20px;
  }
}
.w-70 {
  width: 70%;
}

.practice-search-wrapper {
  position: relative;
  img {
    width: 20px;
    position: absolute;
    top: 12px;
    left: 10px;
  }
  input {
    padding-left: 35px;
    padding-right: 5px;
  }
}

.locationPrimary {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  line-height: 110%;
  max-width: 70%;
}

.locationStoreNum {
  color: #555;
}

.locationAddress {
  text-align: left;
  font-size: 12px;
  line-height: 140%;
  color: #333;
}

.locationIcon {
  font-size: 0.8rem !important;
  margin-right: 3px;
}
.locationIconPad {
  padding-left: 1.3em;
}
.locationPhone {
  display: flex;
  align-items: center;
}

@media (max-width: 575.98px) {
  .practice-search-input {
    width: 100%;
    .spinner {
      height: 10px;
    }
  }
  .practice-search-wrapper {
    width: 90%;
    margin: 0 auto;
  }
}
