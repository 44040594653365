.declined-container,
.credit-application-page {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  height: calc(100vh - 200px);
  flex-flow: column;
  font-family: "'LatoBold'";

  .radio-group__share {
    .editableInput {
      fieldSet {
        display: block;
      }
      label {
        font-weight: bold;
      }
    }
  }

  .content {
    max-width: 800px;
    padding: 50px 0;
    margin: 0 auto;
    width: 80%;
    p {
      color: $black-base;
      font-size: clamp(14px, 2vw, 20px);
      font-family: "'LatoBold'";
      margin-bottom: 20px;
    }
    button {
      font-family: 'LatoRegular';
      margin-top: 16px;
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }

    button.button-here {
      border: none;
      background-color: transparent;
      font-family: 'LatoRegular';
      font-weight: bold;
      color: $blue-base;
      font-size: inherit;
      width: fit-content;
      padding: 0;
      cursor: pointer;
      text-transform: none;
      margin-top: 0px;
    }
  }
  .info-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    gap: 10px;

    svg {
      color: $black-light;
    }
    p {
      font-size: 20px;
    }
    .number-container {
      div {
        margin-bottom: 8px;
      }
      a {
        font-size: 20px !important;
        font-family: 'LatoRegular' !important;
        color: $darkgrey-base !important;
        text-decoration: none;
        font-weight: normal;
      }
    }
  }
  .button-block {
    text-align: left;
    margin-top: 10px;

    button {
      margin: 20px;
      align-self: center;
      width: 25%;
      padding: 1rem;
      margin-bottom: 2rem;
      border: 3px solid $red-base;
      font-size: 14px;
      text-transform: uppercase;
    }

    .warn-disabled {
      border: 1px solid $darkgrey-light;
    }

    a {
      width: 40%;
      margin: 20px;
    }
  }
  .step-up-pend-img {
    width: 60px;
    justify-self: center;
  }
}
