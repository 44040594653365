.account-lookup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

  h1 {
    font-family: "'LatoBold'";
    color: $black-base;
  }

  &__info {
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    font-weight: bold;
    div {
      width: 100%;
    }
  }

  &__submit {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;

    button {
      width: 50%;
      text-transform: uppercase;
      padding: 17px 90px;
      letter-spacing: 1.4px;
    }
  }
  &__empty-state {
    display: flex;
    margin-top: 50px;
    font-size: 18px;
    color: $darkgrey-base;
  }

  .account-lookup-form {
    display: flex;
    align-items: center;
    width: 75%;
    margin-top: 30px;
    form {
      width: 100%;
    }
    fieldset {
      label {
        font-size: 16px;
        font-family: 'LatoRegular';
        color: $black-base;
      }
    }
  }

  .account-lookup-result {
    width: 100%;
    .card {
      border: 1px solid $darkgrey-lighter;
      border-radius: 10px;
      box-shadow: 0 1px 2px rgba($black-base, 0.15);
      transition: box-shadow 0.3s ease-in-out;

      &:hover {
        box-shadow: 0 5px 15px rgba($black-base, 0.3);
      }

      .footer {
        display: flex;
        justify-content: space-between;
      }
      strong {
        font-family: "'LatoBold'";
        font-size: 16px;
        color: $black-base;
      }
    }
    .card-title {
      margin-bottom: 6px;
    }
    .card-area {
      color: $black-base;
      font-size: 14px;
      .indicator-green,
      .indicator-red {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-left: 5px;
        margin-top: 3px;
      }
      .indicator-green {
        background-color: $green-base;
      }
      .indicator-red {
        background-color: $red-base;
      }
    }
    .card-main {
      margin-top: 13px;
    }
    .sub-title {
      .card-area {
        font-size: 17px;
        color: $black-base;
      }
    }
  }
}

.account-lookup-form {
  fieldset {
    display: block;
  }
  .checkbox {
    margin-bottom: 2rem !important;
    width: auto;
    text-wrap: nowrap;
  }
  .accountSearchForm {
    display: flex;
    flex-direction: column;
    &__input {
      width: 30%;
      margin-right: 25px;
    }
    &__number {
      width: 80%;
      margin-right: 27px;
    }

    &__fields {
      display: flex;
      margin-bottom: 20px;

      fieldset {
        height: 80px;
        margin-bottom: unset !important;
      }
      .ssn-input__visibility-icon {
        top: 11%;
      }
    }

    button {
      align-self: center;
      width: 50%;
      padding: 1rem;
      margin-bottom: 2rem;
      border: 3px solid $red-base;
      font-size: 14px;
      text-transform: uppercase;
    }

    .error-message,
    span.error {
      padding: unset;
    }
  }
  .ssn-zip {
    .accountSearchForm__fields {
      display: flex;
      justify-content: space-between;
    }
    .ssn-input--wrapper,
    .accountSearchForm__input {
      width: 45%;
    }
  }
  input[type='checkbox'] {
    border-radius: 30px;
    background: unset;
    border: 2px solid $black-base;

    &:checked {
      background-color: #fff;
    }
  }

  input[type='checkbox']:checked {
    &::after {
      border-radius: 30px;
      left: 2px;
      top: 2px;
      background-color: $black-base;
      background-image: unset;
    }
  }
}
.attention-paragraph {
  margin-bottom: 20px;
  display: flex;
}
.new-deal-signer-modal-container,
.new-deal-non-signer-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  span {
    text-align: center;
    padding: 3px;
  }
  button {
    padding: 16px;
    margin: 8px 0 8px 0;
    width: 80%;
  }
  .signers-list {
    text-align: center;
    // margin: 14px 0;
  }
}
.send-receipt-extra-info {
  p {
    font-style: italic;
  }
  span {
    font-style: normal;
  }
  margin-bottom: 40px;
}

.sms-extra-info {
  text-align: center;
  margin-bottom: 20px;

  h3 {
    font-weight: bold;
    font-size: 18px;
    text-align: left;
  }
  p {
    text-align: left;
  }
  span {
    text-align: center;
    font-size: 14px;
    color: $black-base;
  }
}
.sms-extra-info > div {
  border: 1px solid $darkgrey-light;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.sms-extra-checkbox {
  label {
    display: flex;
  }

  margin-bottom: 20px;
  p {
    width: 100%;
    font-size: 14px;
    line-height: 115%;
  }
}

.icon-text-attention {
  border: 1px solid $black-base;
  border-radius: 40px;
  padding: 0;
  width: 24px;
  height: 22px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  margin-right: 10px;
}

.send-receipt-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 460px;
  margin: 0 auto;

  h1 {
    font-size: 30px;
    font-family: "'LatoBold'";
    color: $black-base;
    text-align: center;
  }

  h4 {
    font-size: 20px;
    font-family: "'LatoBold'";
    color: $black-base;
    text-align: center;
  }
  .__communication-method-description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    p {
      font-family: 'LatoRegular';
      color: $black-base;
      font-size: 20px;
      font-weight: 400;
      margin-top: 20px;
    }
    p.less-important {
      font-family: 'LatoRegular';
      color: $black-base;
      font-size: 20px;
    }
    .sent-to {
      .field {
        margin-top: 20px;
      }
    }
    .info-box {
      margin-top: 20px;
      padding: 20px;
      background-color: $lightblue-base;
      .tooltip-icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
  .send-receipt-form {
    width: 100%;
    .email-input {
      padding-left: 8px;
    }
    .send-receipt-buttons {
      .cancel-button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: "'LatoBold'";
        outline: inherit;
        text-decoration: underline;
        color: $black-base;
        opacity: 60%;
      }
    }

    fieldset {
      display: flex;
      flex-direction: column;
      justify-content: center;
      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-family: 'LatoRegular';
        color: $black-base;
        font-size: 20px;
        span {
          margin-left: 26px;
          font-family: 'LatoRegular';
          font-weight: 100;
          color: $black-light;
        }
      }
    }
  }
}
