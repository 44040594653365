// Buttons Component Styles
// ========================================

// Main Button
@mixin button {
  border-radius: 20em;
  display: inline-block;
  @include font-type($primary-sans-serif, normal, $bold);
  @include rem(padding, 10px);
  @include s-base;
  transition: all 0.5s ease-in-out;
  min-width: 170px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border: none;
    @include outline-dark;
    text-decoration: none;
  }
}

// Button Styles
@each $button in $buttons {
  $type: nth($button, 1);
  $bg-1: nth($button, 2);
  $border: nth($button, 3);
  $color: nth($button, 4);
  $hover-bg: nth($button, 5);
  $hover-color: nth($button, 6);

  .button-#{$type},
  a.button-#{$type} {
    background-color: $bg-1;
    border: 1px solid $border;
    color: $color;
    @include button;

    &:hover {
      background-color: $hover-bg;
      border: 1px solid $hover-bg;
      color: $hover-color;
    }

    &:active {
      background-color: $hover-bg;
      border: 1px solid $hover-bg;
      color: darken($hover-color, 5%);
    }

    &:focus {
      border: 1px solid $hover-bg;
    }

    &.is-disabled {
      background-color: $bg-1;
      color: $color;
      cursor: not-allowed;
      opacity: 0.4;
    }

    &.in-progress {
      color: transparent;
      pointer-events: none;
      position: relative;

      &:after {
        animation: spin 0.5s linear infinite;
        border: 2px solid $color;
        border-radius: 50%;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 20px;
        @include position(absolute, 7px null null null);
        left: calc(50% - 10px);
        width: 20px;
      }
    }
  }

  .button-borderless {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    min-width: fit-content !important;
    &:focus {
      outline: none !important;
    }
  }

  // Links
  .link-#{$type} {
    background-color: transparent;
    border: none;
    color: $bg-1;
    display: inline-block;
    @include s-base;
    @include font-type($primary-sans-serif, normal, $bold);
    transition: all 0.3s ease-in-out;
    @include rem(margin-bottom, 20px);
    padding: 0px;
    text-decoration: none;

    &:hover {
      color: $hover-bg;
    }

    &:focus {
      @include outline-dark;
    }
  }
}

// Buton Sizes
.button-small {
  @include rem(padding, 5px 6px);
  @include s-small;
  min-width: inherit;
  width: 100px;
  max-height: 34px;
}

.button-large {
  @include rem(padding, 15px 12px);
}
.external-link-button {
  a {
    color: inherit;
    text-decoration: none;
  }
  &:hover {
    color: $white-base;
  }
}

// Button Disabled
button:hover.is-disabled {
  cursor: not-allowed;
}
button:active.is-disabled {
  pointer-events: none;
}

.btn-close-text {
  margin: 0 auto;
  text-decoration: underline;
  display: block;
  background: transparent;
  border: 1px solid transparent;
  padding: 15px 70px;
  border-radius: 30px;
  cursor: pointer;
}

.btn-close-text:hover {
  border: 1px solid $grey-base;
}

.number-container a {
  font-size: clamp(18px, 2.2vw, 24px);
}
