.internal-portal-modalz {
  @import '@coreui/coreui/scss/coreui';
}

html[data-coreui-theme='dark'] {
  --toastify-text-color-dark: #333;
  --toastify-text-color-dark: #333;

  .internal-portal-layout {
    .modal-header {
      button {
        filter: invert(1); /** Fix Modal Close Button **/
      }
    }

    .form-control:not(:disabled) {
      background: #090d16; /** Form Input Background **/
      position: relative;
    }

    /** Fix Select Dropdown Arrow **/
    select {
      background-image: url('../images/utility-icons/dropdown-white.svg') !important;
      background-repeat: no-repeat !important;
      background-position: right;
      background-size: 20px !important;
      background-position-y: 12px !important;
      background-position-x: 95% !important;
    }
  }
  /** Toastify modal dismiss color **/
  .link-secondary {
    color: var(--toastify-spinner-color);
  }
  .link-secondary {
    color: var(--toastify-text-color-dark);
  }
}

.internal-portal-layout {
  @import '@coreui/coreui/scss/coreui';
  //The below CSS comes from the CoreUI framework and is under their "body" tag, if the package is to be updated,
  //this will need to be updated as well.
  --dark-green: #013220;
  --dark-red: #8b0000;
  --dark-orange: #8b4000;
  --dark-yellow: #9b870c;

  margin: 0;
  font-family: var(--cui-body-font-family);
  font-size: var(--cui-body-font-size);
  font-weight: var(--cui-body-font-weight);
  line-height: var(--cui-body-line-height);
  color: var(--cui-body-color);
  text-align: var(--cui-body-text-align);
  background-color: var(--cui-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(8, 10, 12, 0);
  // This ends the copied CoreUI CSS
  overflow-x: scroll;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .toggle-button {
    input[type='checkbox'] {
      width: 50px !important;
      cursor: pointer;
      border-color: var(--cui-border-color) !important;
    }
  }

  input[type='checkbox']:checked:after {
    display: none;
  }

  .modal {
    .btn {
      width: 5vw;
    }
  }

  ul,
  li {
    list-style: none;
    list-style-type: none;
  }

  input,
  label {
    all: unset;
  }
  input {
    border: 1px solid #343d4d;
    border-radius: 5px;
    padding: 5px;
    &:focus {
      border: 1px solid #212631;
    }
    &.invalid {
      border: 1px solid $red-base;
    }
  }

  .label-checkbox input[type='checkbox'] {
    all: revert;
  }

  span.error {
    font-size: 14px;
    color: #db5d5d;
    font-family: 'LatoRegular';
    width: 100%;
    min-height: 20px;
    text-align: center;
  }

  p {
    color: var(--cui-body-color);
  }

  .card-title {
    border-bottom: 1px solid #555;
    padding-bottom: 20px;
  }

  .btn {
    width: initial !important;
    height: initial !important;
    max-width: initial !important;
  }
  .sidebar.sidebar-container {
    position: relative;
    z-index: 1000;
  }
  .offcanvas-sidebar {
    width: 40vw !important;
    min-width: 400px;
    top: 63px !important;
    left: 256px !important;
    position: absolute !important;
    z-index: 100;
  }
  .form-check-label {
    padding-left: 10px;
  }
  .form-label {
    display: block;
  }
  .form-control.file-input {
    line-height: 250%;
  }
  .label-checkbox {
    display: flex !important;
    align-items: center !important;
    column-gap: 10px;
    cursor: pointer;
  }
  .error-toast {
    position: fixed;
    right: calc(50% - 150px);
    top: 10px;
    width: 300px;
    color: white;
  }

  .sidebar-container {
    min-height: 100vh;
    height: 100%;
    .sidebar-header {
      justify-content: flex-start;
      align-items: center;
      #alphaeonRose {
        height: 30px;
        width: auto;
      }
      #alphaeonLogoBW {
        margin-left: 12px;
        height: 26px;
        width: auto;
      }
    }
    .nav-link {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      max-height: 32px;
      padding: 32px 0 32px 12px;
    }
  }
  .portal-dashboard {
    width: 100%;
    min-width: fit-content;
    background-color: var(--cui-tertiary-bg);
    .portal-header-container {
      height: 63px;
      margin: 0;
      padding: 0 12px 0 12px;
      .portal-header {
        display: flex;
        flex-direction: row;
        max-height: 65px;
        .theme-and-sign-out {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          max-height: 65px;
          .dropdown-item {
            width: 100%;
            justify-content: center;
          }

          button {
            max-height: 45px;
            width: 100px;
          }
        }
      }
    }
    .portal-main {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 24px 0 24px 0;
      min-width: 100%;
      width: fit-content;
      height: 100%;
      min-height: 1000px;
      .main-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        min-height: fit-content;
        &.global-search {
          width: 1500px;
        }
        &.home {
          width: 100%;
          .looker-iframe {
            display: block;
            padding: 50px;
          }
          .home-header {
            h1 {
              color: black;
            }
            h2 {
              color: blue($color: #000000);
            }
          }
        }
        .global-search-input-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          padding: 12px;
          width: fit-content;
          button {
            max-height: 45px;
            width: 100px;
            margin: 3px 0 0 12px;
          }
          select {
            margin: 6px 12px 0 0;
            width: 250px;
          }
          .input-and-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 300px;
            margin-top: 6px;
            input {
              width: 200px;
            }
          }
        }

        .client-access-data {
          padding: 20px;
          align-self: start;
          .accountfield {
            font-size: 20px;
            font-family: 'Open Sans', sans-serif;
            b {
              font-weight: 600;
              margin-right: 10px;
            }
          }
        }
        .no-results-found {
          h4 {
            margin-bottom: 50px;
            font-size: 20px;
          }
        }
        .results-found-header {
          padding: 15px;
        }

        h2 {
          color: var(--cui-emphasis-color);
        }

        .row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }
        .table-container-column {
          height: fit-content;
          min-height: 400px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-left: 10px;
          &.section-half {
            width: 49%;
          }
          &.section-big {
            width: 69%;
          }
          &.section-small {
            width: 29%;
          }
          &.section-full {
            width: 100%;
          }
          .spinner {
            margin-top: 50px;
          }
          .no-results-found,
          .error {
            width: 100%;
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .warning-icon {
              color: var(--cui-danger);
              margin: 12px 0 12px 0;
            }
            button {
              max-height: 45px;
              width: 200px;
            }
            .icon {
              margin-left: 10px;
            }
          }
        }
        .url-tooltip {
          user-select: text;
          cursor: text;
        }
        .table-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          padding: 10px;
          width: 100%;
          overflow-x: auto;
          th {
            width: 100px !important;
          }
          .tooltip-container {
            position: fixed;
            text-align: left;
            display: none;
            width: 'auto';
            z-index: 3;
            box-shadow: 1px 1px 4px black;
            padding: 10px;
            background-color: var(--cui-body-bg);
            margin-left: 100px;
            &.visible {
              display: block;
            }
          }
          .filter-popover {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            border: 1px solid black;
            height: 100px;
            background-color: var(--cui-body-bg);
            box-shadow: 1px 1px 1px black;
            .filter-close {
              color: var(--cui-body-color);
              width: 22px;
              margin: 0;
              padding: 0;
            }
            .input-and-reset {
              padding-left: 12px;
            }
            button {
              margin: 10px;
            }
          }
          .sort-controls {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
          .sort-or-filter-icon {
            cursor: pointer;
            &.active {
              border: 1px solid black;
              background-color: var(--cui-info);
              border-radius: 5px;
            }
          }
          .filter-close {
            color: black;
            width: 25px;
            height: 25px;
            padding: 5px;
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
          }
          .popover-container {
            display: flex;
            flex-direction: row;
          }
        }
      }
    }
  }
  .filterable-sortable-pagination {
    padding: 15px 0 15px 0;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-self: center;
    margin: 0 auto;
    strong {
      margin-left: 5px;
    }
    button {
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
  }
  .login-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #212631;

    .login-card {
      width: 500px;
      height: fit-content;
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #292f3d;
      box-shadow: 0px 0px 2px black;
      h2,
      h5 {
        color: #d8dddc;
      }
      h5 {
        margin-bottom: 50px;
      }
      .alphaeon-logo {
        width: 200px;
        margin-bottom: 50px;
      }
      .google-logo {
        width: 40px;
        margin-right: 30px;
      }
    }
  }
  .filter-input {
    color: black;
  }

  .location-search {
    width: 300px;
    margin-left: auto;
    input[name='searchCriteria'] {
      width: 255px;
      margin-left: 0px;
      padding-left: 40px;
      &:focus {
        border: 1px solid #555;
      }
    }
    .locationAddress,
    .locationStoreNum {
      color: #fff;
    }
    .magGlass {
      width: 20px;
      filter: invert(1);
      top: 8px;
      left: 15px;
    }
    ul {
      width: 300px;
      list-style: none;
      background-color: var(--cui-secondary-bg);
      position: absolute;
      z-index: 1000;
      list-style-type: none;
      padding: 0px;

      li:hover {
        background-color: var(--cui-secondary-bg);
        filter: brightness(1.05);
      }

      li {
        marker: none;
        list-style-type: none;
        width: 100%;
        color: var(--cui-body-color);

        .liContainer {
          padding: 10px;
        }
        div:hover {
          background-color: transparent;
        }
        span:hover {
          background-color: transparent;
        }
        .ellipsis {
          .locationPrimary {
            font-weight: bold;
            display: block;
          }
          .locationAddress {
            display: block;
          }

          .locationPhone {
            display: block;
          }
        }
      }
    }
  }

  .location-view {
    font-family: 'LatoRegular';
    width: 70vw;

    .location-prefix {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .location-card {
      flex-direction: row;
    }

    .box-align {
      display: flex;
      justify-content: space-between;
      width: 70%;
      margin-bottom: 10px;
      text-wrap: nowrap;
      h5,
      p {
        margin: 0;
        padding: 0;
      }
      .data-item {
        text-align: left;
      }
    }
    .location-subcard {
      width: 100%;
      display: grid;
    }

    .status {
      width: fit-content;
      margin-left: 10px;

      span.status-indicator {
        display: inline-block;
        width: fit-content;
        height: fit-content;
        border-radius: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 5px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .lender-display,
    .location-feature {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0;

      .lender-name {
        font-size: 20px;
        font-weight: bold;
        color: var(--cui-primary);
      }

      .toggle-button {
        display: flex;
        flex-direction: column;
        align-items: end;
        margin: 5px;
        label {
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }
    .lender-display {
      .toggle-button {
        .form-check-input:disabled {
          display: none;
        }
        .form-switch .form-check-input:checked {
          display: block;
        }
        .form-check-input:checked {
          display: block;
        }
      }
    }

    .location-feature {
      .toggle-button {
        .form-check-input:disabled {
          display: block;
        }
        .form-switch .form-check-input:checked {
          display: block;
        }
        .form-check-input:checked {
          display: block;
        }
      }
    }

    .location-box {
      margin: 0;
    }

    .location-ids {
      margin-left: 10px;

      .data-item {
        text-align: left;
        margin-left: 15px;
        color: var(--cui-primary);

        .data-label {
          font-size: 12px;
        }
      }
    }
    .location-information {
      margin-left: auto;
      margin-right: 0;

      .data-item {
        text-align: center;
        display: inline-block;
        margin-left: 20px;
      }

      .data-value {
        font-size: 16px;
        font-weight: normal;
      }
      .data-label {
        font-size: 10px;
      }
    }

    .location-title {
      font-size: 48px;
      margin-top: 20px;
    }

    .data-item {
      text-align: center;
      display: inline-block;
      margin-left: 20px;
    }

    .data-value {
      font-size: 24px;
      font-weight: bold;
    }

    .data-label {
      font-size: 14px;
    }
  }

  .account-view {
    font-family: 'LatoRegular';
    width: 70vw;

    .data-item {
      text-align: left;
      color: var(--cui-primary);
      display: block;
      margin-top: 10px;

      .data-label {
        font-size: 12px;
      }
    }

    .account-primary-information {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 0;

      .primary-pii {
        display: block;

        .data-item {
          text-align: left;
          display: block;
          margin-left: 20px;
        }

        .data-value {
          font-size: 16px;
          font-weight: normal;
        }
        .data-label {
          font-size: 10px;
        }
      }
    }

    .account-card {
      flex-direction: row;
    }

    .name-and-status {
      display: flex;
      align-items: center;
    }
    .status {
      width: fit-content;
      margin-left: 10px;

      span.status-indicator {
        display: inline-block;
        width: fit-content;
        height: fit-content;
        border-radius: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 5px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .account-ids {
      margin-left: 10px;
      display: flex;
      align-items: center;
      font-weight: bold;

      .data-item {
        text-align: left;
        margin-left: 15px;
        color: var(--cui-primary);
        display: inline-block;

        .data-label {
          font-size: 12px;
        }
      }
    }

    ul,
    li {
      color: #fff;
    }

    .box-title {
      width: 100%;
      margin-bottom: 20px;
    }

    .btn {
      width: initial;
      max-width: initial;
      margin: initial;
      height: initial;
    }

    .account-box {
      padding: 0;
      margin: 20px 0;
      text-align: center;
      &__wide {
        padding: 0;
        margin: 20px 0;
        min-width: 20vw;
        text-align: center;
      }
    }

    .box-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 20vw;

      .box-label {
        width: 50%;
        color: #5f6675;
        font-size: 20px;
      }

      input {
        display: inline-block;
        background: transparent;
        width: auto;
        border: none;
        text-align: right;
        font-size: 20px;
        padding: 0;
      }
    }

    .box-align {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      margin-bottom: 10px;
      text-wrap: nowrap;
      h5,
      p {
        margin: 0;
        padding: 0;
      }
    }
    p {
      color: var(--cui-body-color);
      padding-right: 12px;
    }
    .applicant-info-container {
      max-width: 45%;
      .applicant-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-x: auto;
        .applicant-pii-details {
          text-align: left;
          margin-top: 25px;

          svg {
            margin-right: 10px;
          }
        }
      }
    }

    span.status-indicator {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      margin-bottom: 5px;
      margin-right: 5px;
    }

    .status-pending {
      .timeline-item-bar {
        background: var(--cui-warning);
      }
      .timeline-item-bar::after {
        background: linear-gradient(
          to right,
          var(--cui-success) 0%,
          var(--cui-warning) 100%
        ) !important;
      }
    }

    .status-created {
      .timeline-item-bar {
        background: var(--cui-success);
      }
      .timeline-item-bar::after {
        background: var(--cui-success) !important;
      }
    }

    .status-rejected,
    .status-Rejected {
      .timeline-item-bar {
        background: var(--cui-danger);
      }
      .timeline-item-bar::after {
        background: linear-gradient(
          to right,
          var(--cui-success) 0%,
          var(--cui-danger) 100%
        ) !important;
      }
    }

    .panel-box {
      display: flex;
      column-gap: 40px;
      justify-content: space-between;
    }

    .box-stack {
      min-width: 45%;
      .box-label {
        margin: 10px 0;
      }
      > div {
        margin: 0 0 20px;
      }
    }
    /** END account-view **/
  }

  .override-w100 {
    .table-container {
      width: 100% !important;
    }
  }

  /** TIMELINE **/
  .account-timeline {
    margin: 100px 0 0 0;
  }

  .account-title {
    color: #777;
    font-size: 54px;
  }

  [id*='react-chrono-timeline-'] {
    background: transparent;
    width: 78% !important;
    justify-content: flex-start !important;
    margin-right: 50px !important;
    margin-left: 90px !important;
    position: relative;
    top: -50px;
  }

  .account-timeline [class*='Outline-'] {
    width: 78% !important;
    background: #777 !important;
    border-radius: 12px;
  }

  .timeline-card-content {
    background: transparent !important;
    outline: none !important;
  }

  [class*='CardTitle-'] {
    text-align: left !important;
    margin: 0 !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #fff !important;
  }

  .card-sub-title {
    color: #777 !important;
    font-weight: 400 !important;
  }

  [class*='TimelineContentContainer-'] {
    outline: none !important;
  }

  .timeline-item-title {
    color: #fff !important;
  }

  .timeline-circle {
    width: 37px !important;
    height: 37px !important;
    background: #777 !important;
    border: none !important;
  }

  .timeline-horz-item-container {
    width: 11vw !important;
  }

  /** TIMELINE **/
  .account-timeline2 {
    margin: 75px 0 50px;
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    .timeline-bar {
      width: 98%;
      height: 20px;
      background: var(--cui-secondary-color);
      position: absolute;
      top: 50%;
      margin-top: -10px;
      margin-left: 1%;
      border: 6px solid var(--cui-secondary-bg);
      z-index: 1;
    }

    .timeline-item-bar {
      width: 50px;
      height: 50px;
      background: var(--cui-success);
      border-radius: 40px;
      margin: 15px 0 0;
      border: 8px solid var(--cui-secondary-bg);
      z-index: 2;
      position: absolute;
      top: 35px;

      &::after {
        content: '';
        position: absolute;
        background: var(--cui-success);
        left: -26%;
        height: 8px;
        width: 55px;
        top: 11.5px;
        display: none;
      }
    }

    .timeline-item {
      height: 150px;
      width: 200px;
      background: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      text-align: center;

      .timeline-info {
        position: relative;
        font-size: 14px;
      }

      .timeline-info::before {
        content: '';
        position: absolute;
        top: -14px;
        left: 50%;
        width: 1px;
        height: 12px;
        background: #fff;
        z-index: 0;
      }
    }

    .timeline-item:first-child {
      align-items: flex-start;
      .timeline-date {
        position: relative;
        left: -20px;
      }
      .timeline-info {
        position: relative;
        left: -30px;
      }

      .timeline-item-bar {
        &::after {
          left: 20px;
        }
      }
    }
  }

  .timeline-info {
    top: 12px;
  }

  .timeline-item:nth-last-child(-n + 2) {
    align-items: flex-end;
    top: -8px;
    .timeline-date {
      position: relative;
      right: -5px;
    }

    .timeline-info {
      position: relative;
      right: -4px;
      top: 0px;
    }

    .timeline-item-bar {
      top: 42px;
      &::after {
        left: -190px;
        width: 200px;
      }
    }
    .timeline-item-bar::after {
      top: 12.5px;
    }
  }

  .status-yellow {
    .timeline-item-bar {
      background: var(--cui-warning);
    }

    .timeline-item-bar::after {
      background: linear-gradient(
        to right,
        var(--cui-success) 0%,
        var(--cui-warning) 100%
      );
    }
  }

  span.status-yellow {
    background: var(--cui-warning);
    color: var(--dark-yellow);
  }

  .status-orange {
    .timeline-item-bar {
      background: #fd7e14;
    }

    .timeline-item-bar::after {
      background: linear-gradient(
        to right,
        var(--cui-success) 0%,
        #fd7e14 100%
      );
    }
  }

  span.status-orange {
    background: #fd7e14;
    color: var(--dark-orange);
  }

  .status-green {
    .timeline-item-bar {
      background: var(--cui-success);
    }

    .timeline-item-bar::after {
      background: linear-gradient(
        to right,
        var(--cui-success) 0%,
        var(--cui-success) 100%
      );
    }
  }

  span.status-green {
    background: var(--cui-success);
    color: var(--dark-green);
  }

  .status-red {
    .timeline-item-bar {
      background: var(--cui-danger);
    }

    .timeline-item-bar::after {
      background: linear-gradient(
        to right,
        var(--cui-success) 0%,
        var(--cui-danger) 100%
      );
    }
  }

  span.status-red {
    background: var(--cui-danger);
    color: var(--dark-red);
  }

  .field-attachments-pdf-viewer {
    max-height: 500px;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;

    ul {
      margin-bottom: 0;
    }
  }

  .fields-viewer-pagination {
    position: sticky;
    bottom: -1px;
    background: #000;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
