html,
body,
#root {
  height: 100%;
}

body {
  &.modal-open {
    overflow: hidden;
    // Prevent scroll on ios safari
    position: fixed;
    width: 100%;
    height: 100%;
    // Prevent pull-to-refresh on ios chrome
    overscroll-behavior-y: contain;
  }
}

main {
  position: relative;
}

[class*='-block-container'] {
  @include base-padding;
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  width: calc(100% - 245px);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

hr {
  border: none;
  border-bottom: 1px solid $grey-light;
}

.bold {
  font-family: "'LatoBold'";
}

.account-number {
  display: flex;
  justify-content: center;
  gap: 10px;
  .number {
    margin-top: 10px;
    text-wrap: nowrap;
  }
  &__visibility-button {
    display: flex;
    border: unset;
    background: unset;
    min-width: unset;
    cursor: pointer;
    svg {
      color: $black-light;
    }
    &:hover,
    &:focus {
      border: unset;
      background: unset;
    }
  }
}

.email-phone-link {
  color: $darkgrey-base;
  font-size: 16px;
  font-family: 'LatoRegular';
  text-decoration: none;
  font-weight: normal;
}

.status-icon {
  margin-right: 10px;
  vertical-align: -2px;
  width: 15px;
}

.link-secondary {
  min-width: auto;
  text-decoration: underline;
  margin-bottom: 0px;
  // margin-left: 5px;
  color: $black-base;
  font-size: 12px;

  &:hover {
    background-color: transparent;
    border: none;
  }
}

.border-bottom-none {
  input {
    border-bottom: none !important;
  }
}

.back-button {
  svg {
    height: 18px;
  }
}

.w-70 {
  width: 70%;
}

.print-receipt-button {
  margin-right: 20px;
  margin-bottom: 5px;
  padding: 0.625rem !important;
  max-width: 200px;
}
.custom-option {
  transition: background 60ms;
}
.custom-option:hover {
  transition-delay: 60ms;
  background: $lightblue-base;
}
.custom-option.custom-select__option--is-focused {
  background: $lightblue-base;
}
.custom-option.custom-select__option--is-selected {
  background: $blue-base;
}

.custom-select {
  &__control {
    margin-top: 20px !important;
    border: unset !important;
    border-bottom: 2px solid $black-base !important;
    border-radius: unset !important;
    border-color: unset !important;
  }

  &__truncation-label {
    // To match ReactSelect default
    font-size: 85%;
    margin-left: 4px;
  }
}

label {
  color: $black-base;
}

.transaction-filter {
  flex-direction: column;
  &--item {
    width: 100%;
  }
  .checkbox-group {
    display: flex;
    width: 50% !important;
  }
}

.credit-declined {
  .content {
    p {
      font-family: 'LatoRegular' !important;
    }
  }
  .check-qualification {
    display: flex;
    width: 80%;
    padding-left: 20px;
  }
}
.prequal-decline-modal-overlay {
  backdrop-filter: blur(2px);
  background-color: transparent !important;
}
.prequal-decline-modal {
  height: auto;
  border-top: 10px solid $red-base;
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    flex-direction: column;
    padding: 20px;
    .details {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black-base;
      font-family: "'LatoBold'";
      line-height: 1.3;
    }
    .button-block {
      margin-top: 40px;
    }
  }
}

.activity-filter-form {
  form {
    width: 100% !important;
    .checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 40%;
    }
  }
}
