.account-summary {
  position: relative;
}

.summary {
  width: 80%;
  div {
    color: $black-base;
    .react-datepicker__day--disabled {
      cursor: default;
      color: $grey-base;
    }
  }
  p {
    color: $black-base;
  }

  &__info {
    font-size: 20px;
    font-family: "'LatoBold'";
    line-height: 26px;
  }
  &__card {
    margin: unset;
    margin-top: 20px;
    border: 3px solid $lightgrey-base;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    &--bottom {
      margin: unset;
      border-top: none;
      padding-top: 15px;
    }
  }

  &__account-info {
    &__for-view {
      width: 100%;
    }
    width: 100%;
    margin: unset;
    &--header {
      display: flex;
      justify-content: space-between;
    }
  }

  .sub-title {
    font-family: "'LatoBold'";
    margin-top: 3px;
    font-size: 20px;
    color: $darkgrey-base;
  }
  .card-main {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .sale {
    min-width: 130px;
  }
  .note {
    font-family: 'LatoRegular';
    font-style: italic;
    font-size: 16px;
  }
  .voidable-transactions {
    &__void-helper {
      margin-top: 20px;
      font-size: 15px;
      color: $darkgrey-base;
      font-family: "'LatoBold'";
      line-height: 22px;
    }
  }
  .empty-transaction-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    color: grew;
    font-size: 20px;
    color: $darkgrey-base;
  }

  &__actions {
    display: flex;
    width: 100%;
    .submit {
      width: 50%;
    }
    .cancel {
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: $darkgrey-base;
      letter-spacing: 1.4px;
      cursor: pointer;
    }
    button {
      width: 100%;
    }
  }
  .email-phone-link {
    color: $black-base;
    font-size: 16px;
    font-family: 'LatoRegular';
    text-decoration: none;
    font-weight: normal;
  }
  .capitalize {
    font-size: 16px;
  }
  .bottom-card {
    margin-top: unset !important;
    border-top: unset;
  }
}

.plan-card {
  width: 100%;
  border: 1px solid $grey-base;
  border-radius: 4px;
  .top-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid $grey-base;
    padding: 28px 24px;
  }
  .bottom-card {
    padding: 0px 24px 28px 24px;
  }
  .card-main {
    display: flex;
    justify-content: space-between;
    padding: 24px;
  }
  .sale {
    min-width: 130px;
  }
}

@media (max-width: 505px) {
  .summary {
    width: 100%;
  }
}
.heading-subtitle {
  font-size: 20px;
  font-family: 'LatoRegular';
  text-decoration: unset;
}

.header-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  height: 38px;
}
