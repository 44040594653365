:root {
  --max-modal-width: 592px;
}

p {
  font-size: 16px;
  margin-bottom: 0;
}

ul {
  padding: 0 20px;
  li {
    list-style-type: disc;
    font-size: 14px;
  }
}
sup {
  vertical-align: super;
  font-size: smaller;
}
small {
  font-size: 12px;
}

ul.list-style-type-none {
  li {
    list-style-type: none !important;
  }
}

iframe {
  border-radius: 5px;
}

/** Font Color **/
.color-black {
  color: $black-base;
}
.color-gray {
  color: $darkgrey-base !important;
}
.color-white {
  color: $white-base;
}

/** Font Weights **/
.weight-400 {
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
.weight-700 {
  font-weight: 700;
}
/** Paragraphs **/
.p-1 p,
p.p-1 {
  margin: 20px 0;
}
.p-16,
p.p-16,
.p-16 p {
  font-size: clamp(14px, 1.6vw, 16px);
  font-family: 'LatoRegular';
  line-height: 19.2px;
}
.p-20,
p.p-20,
.p-20 p {
  font-size: clamp(18px, 2.2vw, 22px);
  font-family: 'LatoRegular';
  line-height: 160%;
}
.btn-legacy {
  width: 80vw;
  max-width: 400px;
  margin: 10px 0;
  height: 64px;
}

.btn-link {
  height: auto;
  background: transparent;
  color: $blue-base;
  border: none;
  border-bottom: 1px solid $blue-base;
  font-size: inherit;
  font-weight: lighter;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
}

.btn-link:hover {
  color: $black-base;
}

.clickable-el {
  cursor: pointer;
}

.button-lg {
  margin: 0 10px;
  padding: 17px 30px;
  letter-spacing: 1.4px;
}
.button-warn {
  padding: 17px 30px;
  letter-spacing: 1.4px;
}

.MuiInputBase-root.MuiInput-underline:after {
  border-bottom: 1px solid $black-light;
}

.MuiFormLabel-root.Mui-focused.MuiInputLabel-root {
  color: $black-base;
  font-family: "'LatoBold'";
  font-size: 25px;
}

label.MuiInputLabel-animated.MuiFormLabel-filled {
  color: $black-base;
  font-family: "'LatoBold'";
  font-size: 25px;
}

.MuiInput-root.MuiInput-formControl {
  margin-top: 25px;
}

.social-icon {
  cursor: pointer;
}

.qualify {
  font-family: 'LatoBlack';
  font-size: 14px;
}

.qualify-lg {
  font-family: 'LatoBlack';
  font-size: 16px;
}

.simple-form {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.rm-absolute,
.rm-fixed {
  position: relative;
}
.z_-1 {
  z-index: -1;
}
.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}
.z-100 {
  z-index: 100;
}
.z-max {
  z-index: 9999;
}
.z-inifinity {
  z-index: 99999;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  &__column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.d-flex {
  display: flex;
}
.box-shadow-glow {
  box-shadow: 0px 4px 20px 0px #00000014;
}

.ssn-input {
  position: relative;
  margin-bottom: 3rem;
  flex-direction: column;
  align-items: flex-start;

  input,
  select {
    border: unset;
    padding: 1em 0;
    border-bottom: 1px solid $black-base;
    &::placeholder {
      font-style: normal;
    }
  }
  label {
    align-self: baseline;
  }
  &__visibility-icon {
    cursor: pointer;
    color: $darkgrey-base;
    align-self: end;
    position: absolute;
    top: 25px;
  }
}
.input-tips {
  margin-top: -30px;
  margin-bottom: 3rem;
}
.print-page {
  height: 100vh;

  .proxy-iframe {
    height: 100%;
    width: 100%;
  }
}
@media print {
  .proxy-iframe {
    display: none;
  }
  .iframe {
    width: 100vw;
  }
  .financial-terms {
    height: 2150vh;
  }
  .patriot-terms {
    height: 150vh;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 74vw;
  }
}

.w-auto {
  width: auto !important;
}
.w-80 {
  width: 80%;
}

.container-slim {
  max-width: 600px;
  width: 90vw;
  padding: 20px;
}

.display-inline {
  display: inline-block;
}

/** TEXT & FONTS **/
.text-red {
  color: $red-base;
}
.text-bold {
  font-weight: bold;
}
.text-italic-blue {
  color: $blue-base;
  font-style: italic;
  font-size: 16px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-underline {
  text-decoration: underline;
}
.text-no-wrap {
  text-wrap: nowrap;
}

.text-linethrough {
  text-decoration: line-through;
}

.no-decor {
  text-decoration: none;
}

.decimal {
  list-style: decimal;
}

.lower-alpha {
  list-style-type: lower-alpha;
}

.lower-roman {
  list-style-type: lower-roman;
}

.font-mono {
  font-family: 'Courier New', Courier, monospace !important;
  font-size: clamp(14px, 1.8vw, 18px) !important;
  text-transform: uppercase;
}
.font-xbold {
  font-weight: bolder;
}
.font-bold {
  font-weight: bold;
}
.font-white {
  color: $white-base;
}
.font-reg {
  font-weight: normal;
}
.font-weight-400 {
  font-weight: 400;
}

.button-primary {
  font-family: 'LatoRegular';
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

button:disabled,
button:disabled:hover {
  cursor: not-allowed;
  color: $darkgrey-base;
}

button.button-warn:disabled {
  color: $white-base;
  background: $black-light;
  border: $black-light;
}

.font-bold {
  font-family: "'LatoBold'";
}
.font-italic {
  font-style: italic;
}
.font-weight-400 {
  font-weight: 400;
}
.capitalize {
  text-transform: capitalize;
}
.capitalize:before {
  content: ''; /* empty string, no specials here */
  display: inline-block;
}

.external-link {
  font-style: italic;
  font-size: 20px;
  color: $blue-base;
}

.text-as-link {
  color: $blue-base;
  cursor: pointer;
  text-decoration: underline;
}

.text-as-button {
  color: $black-base;
  font-family: 'LatoRegular';
  font-size: 14px;
  font-weight: 900;
  cursor: pointer;
}

.text-blue {
  color: $blue-base;
  &:hover,
  &:focus {
    color: $blue-base;
  }
}

/** FLEX **/
.flex {
  display: flex;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-wrap-md {
  display: flex;
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
}

.flex-wrap-1200 {
  display: flex;
  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

.flex-dir-col {
  flex-direction: column !important;
}
.space-between {
  justify-content: space-between !important;
}
.align-start {
  align-items: flex-start !important;
}
.align-center {
  align-items: center !important;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-self-end {
  display: flex;
  align-items: self-end;
}
.col-gap-20 {
  gap: 20px;
}
.col-gap-10 {
  gap: 10px;
}
.col-gap-0 {
  gap: 0;
}
.row-gap-10-md {
  @media screen and (max-width: 991px) {
    row-gap: 10px;
  }
}
.dir-row {
  flex-direction: row;
}
.dir-col {
  flex-direction: column;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.align-self-start {
  align-self: flex-start;
}
.margin-auto {
  margin: auto;
}
.max-height-600 {
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
}
.min-height-600 {
  min-height: 600px;
}
.max-width-300 {
  max-width: 300px;
}
.max-width-500 {
  max-width: 500px;
}
.max-width-600 {
  max-width: 600px;
}
.align-self-end {
  align-self: flex-end;
}
.h100 {
  height: 100%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}
.vh100 {
  height: 100vh;
}
.vh80 {
  height: 80vh;
}
.vw100 {
  width: 100vw;
}
.vw90 {
  width: 90vw;
}
.vw80 {
  width: 80vw;
}

.max-width-600 {
  max-width: 600px;
}
.max-width-800 {
  max-width: 800px;
}
.max-height-100 {
  max-height: 100px !important;
}

.bg-white {
  background: $white-base;
}
.bg-black {
  background: $black-base;
}
.bg-light-blue {
  background: $lightblue-base;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow-y: scroll;
}

@media (max-width: 767px) {
  .w100 {
    width: 100% !important;
  }
  .w70,
  .w75 {
    width: 100% !important;
  }
  .w50 {
    width: 100% !important;
  }
  .w33 {
    width: 100% !important;
  }
  .w30 {
    width: 100% !important;
  }
  .w25 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w100 {
    width: 100% !important;
  }
  .w70 {
    width: 68% !important;
  }
  .w75 {
    width: 73% !important;
  }
  .w50 {
    width: 50% !important;
  }
  .w33 {
    width: 29% !important;
  }
  .w30 {
    width: 28% !important;
  }
  .w25 {
    width: 22% !important;
  }
}
.mar-0 {
  margin: 0 !important;
}
.mar-auto {
  margin: 0 auto;
}
.mar-10 {
  margin: 10px;
}
.mar-y-8 {
  margin: 8px 0;
}
.mar-y-10 {
  margin: 10px 0;
}
.mar-x-10 {
  margin: 0 10px;
}
.mar-20 {
  margin: 20px;
}
.mar-y-20 {
  margin: 20px 0;
}
.mar-y-32 {
  margin: 32px 0;
}
.mar-x-20 {
  margin: 0 20px;
}
.mar-t-0 {
  margin-top: 0 !important;
}
.mar-t-4 {
  margin-top: 4px;
}
.mar-t-8 {
  margin-top: 8px;
}
.mar-t-10 {
  margin-top: 10px;
}
.mar-t-16 {
  margin-top: 16px;
}
.mar-t-20 {
  margin-top: 20px;
}
.mar-t-24 {
  margin-top: 24px;
}
.mar-t-32 {
  margin-top: 32px;
}
.mar-t-40 {
  margin-top: 40px !important;
}
.mar-t-64 {
  margin-top: 64px;
}
.mar-b-0 {
  margin-bottom: 0;
}
.mar-b-8 {
  margin-bottom: 8px;
}
.mar-b-10 {
  margin-bottom: 10px;
}
.mar-b-16 {
  margin-bottom: 16px;
}
.mar-b-20 {
  margin-bottom: 20px;
}
.mar-right-10 {
  margin-right: 10px;
}
.mar-left-10 {
  margin-left: 10px !important;
}
.mar-left-18 {
  margin-left: 18px;
}
.mar-right-20 {
  margin-right: 20px;
}
.mar-left-20 {
  margin-left: 20px !important;
}
.mar-left-0 {
  margin-left: 0 !important;
}

.pad-none,
.pad-0 {
  padding: 0 !important;
}
.pad-8 {
  padding: 8px;
}
.pad-20 {
  padding: 20px;
}
.pad-40 {
  padding: 40px;
}

.pad-y-20 {
  padding: 20px 0;
}
.pad-y-40 {
  padding: 40px 0;
}
.pad-y-100 {
  padding: 100px 0;
}

.pad-t-10 {
  padding-top: 10px;
}

.pad-t-15 {
  padding-top: 15px;
}

.pad-t-20 {
  padding-top: 20px;
}

@media (max-width: 420px) {
  .mob-pad-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .pad-y-40 {
    padding: 10px 0;
  }
  .pad-y-100 {
    padding: 25px 0;
  }
}

@media (max-width: 767px) {
  .touch-hidden {
    display: none;
  }
  .touch-only {
    display: block;
  }
}

@media (min-width: 768px) {
  .touch-only {
    display: none;
  }
}

@media (min-width: 1100px) {
  .desk-only {
    display: initial;
  }
}

.display-none {
  display: none !important;
}

.not-possible {
  background: $red-base;
  color: $white-base;
  padding: 10px;
  display: flex;
  width: 80vw;
  text-align: center;
  border-radius: 10px;
  justify-content: center;
  margin: 20px 0 40px;
}

.border-none {
  border: none !important;
}

.icon-container {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}

ul.list-style-none li {
  list-style: none !important;
  display: flex;
  margin: 5px 0;
}

.list-prefix-asterisk::before {
  content: '*';
  color: $black-base;
  margin-right: 5px;
}

.list-prefix-dash::before {
  content: '-';
  color: $black-base;
  margin-right: 5px;
}
.cursor-pointer {
  cursor: pointer;
}
