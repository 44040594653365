.action-result-container {
  height: calc(100vh - 200px);
  font-family: "'LatoBold'";
  max-width: 1050px;
  margin: 0 auto;
  padding-top: 48px;
  width: 96%;
  max-width: 1000px;

  header {
    padding-bottom: 2rem;

    h1 {
      font-family: 'LatoBlack';
      font-size: 1.5rem;
    }
  }

  p {
    color: $black-base;
    font-family: inherit;
  }

  a {
    color: $blue-base;
    font-family: inherit;
    font-size: 1rem;
    font-style: italic;
  }

  .button-primary {
    width: 50%;
  }

  .next-step {
    margin-top: 2rem;
  }

  .result-table {
    border: 1px solid $darkgrey-lighter;
    margin-bottom: 30px;
    margin-top: 30px;

    th {
      background: $darkgrey-lighter;
      color: $black-base;
      font-size: 13px;
      letter-spacing: 1px;
    }
    thead {
      border-bottom: unset;
    }
    td {
      font-size: 13px;
      font-family: 'LatoRegular';
      margin: unset;
    }

    .action {
      display: flex;
      font-family: inherit;
      gap: 20px;

      button {
        font-family: 'LatoRegular';
        font-size: 12px;
        letter-spacing: 0;
        padding: 8px 3vw;
        min-width: 70px !important;
        max-width: 200px;
        white-space: nowrap;
      }
    }

    @media (min-width: 992px) {
      th {
        font-size: 16px;
      }
      td {
        font-size: 16px;
      }
      .action {
        button {
          padding: 8px 60px;
          font-size: 16px;
          max-width: 200px;
        }
      }
    }

    @media (max-width: 440px) {
      th {
        font-size: 10px;
      }
      td {
        font-size: 60%;
      }
      .action {
        button {
          padding: 5px 2px;
          min-width: 60px !important;
          font-size: 9px;
          max-width: 90px;
        }
      }
    }
  }
}
